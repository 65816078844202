import React from 'react';
import SolycoIcon from 'ui/Icons/SolycoLogo';

import { FooterWrapper, Navigation } from './styles';

export default function SolycoFooter() {
  return (
    <FooterWrapper>
      <SolycoIcon />
      <Navigation>{null}</Navigation>
    </FooterWrapper>
  );
}
