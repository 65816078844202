import { useEffect, useState } from 'react';
import LogoNavbar from 'ui/Logos/LogoNavbar';
import theme from 'ui/theme/config';
import { VariantTypes } from 'ui/uiTypes';

import { Burger } from '../Burger';
import { NavbarLinks } from '../NavbarLinks';
import { Profile } from '../Profile';
import { Sidebar } from '../Sidebar';
import { DataType } from '../types';
import {
  HeaderV1Wrapper,
  HeaderV2Wrapper,
  HeaderV3Wrapper,
  HeaderWrapper,
  ProfileWrapper,
  Title,
} from './styles';

interface HeaderV1Props extends DataType {
  isMobile?: boolean;
}

interface HeaderV2Props extends DataType {
  isMobile?: boolean;
  isMenuOpen?: boolean;
  handleCloseMenu?: () => void;
  handleToggleMenu?: () => void;
}

interface HeaderV3Props extends DataType {
  isMobile?: boolean;
  isMenuOpen?: boolean;
  handleCloseMenu?: () => void;
  handleToggleMenu?: () => void;
}

interface HeaderProps {
  isMobile?: boolean;
  variant: number;
  data: DataType;
}

export default function Header({ isMobile, variant, data }: HeaderProps) {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>();

  const handleCloseMenu = () => {
    setIsMenuOpen(false);
  };

  const handleToggleMenu = () => {
    setIsMenuOpen((isMenuOpen) => !isMenuOpen);
  };

  useEffect(() => {
    if (isMenuOpen !== undefined) {
      setIsMenuOpen(undefined);
    }
    return () => setIsMenuOpen(undefined);
  }, [isMobile]);

  switch (variant) {
    case 1: {
      return <HeaderV1 isMobile={isMobile} {...data} />;
    }
    case 2: {
      return (
        <HeaderV2
          isMobile={isMobile}
          isMenuOpen={isMenuOpen}
          handleCloseMenu={handleCloseMenu}
          handleToggleMenu={handleToggleMenu}
        />
      );
    }
    case 3: {
      return (
        <HeaderV3
          isMobile={isMobile}
          isMenuOpen={isMenuOpen}
          handleCloseMenu={handleCloseMenu}
          handleToggleMenu={handleToggleMenu}
        />
      );
    }
    default: {
      return null;
    }
  }
}

/* 
	Header V1 is currently used in
		- SalesRep
*/
function HeaderV1({ isMobile, title }: HeaderV1Props) {
  return (
    <HeaderWrapper
      backgroundColor={theme.colors.grey[isMobile ? 0 : 600]}
      data-testid="header-wrapper"
    >
      <HeaderV1Wrapper>
        {isMobile ? (
          title && <Title>{title}</Title>
        ) : (
          <LogoNavbar
            width={111}
            height={37}
            variant={'secondary' as VariantTypes}
            strokeWidth={1}
          />
        )}
      </HeaderV1Wrapper>
    </HeaderWrapper>
  );
}

/* 
	HeaderV2 is currently used in:
		- Home Page
*/
function HeaderV2({ isMobile, isMenuOpen, handleToggleMenu }: HeaderV2Props) {
  return (
    <HeaderWrapper
      backgroundColor={theme.colors.grey[600]}
      data-testid="header-wrapper"
    >
      <HeaderV2Wrapper>
        {isMobile && (
          <Burger isOpen={isMenuOpen} handleClick={handleToggleMenu} />
        )}
        <LogoNavbar
          width={isMobile ? 173 : 111}
          height={isMobile ? 22 : 37}
          variant={'secondary' as VariantTypes}
          strokeWidth={1}
        />
        {!isMobile && <NavbarLinks type="home" />}
      </HeaderV2Wrapper>
      {isMobile && <Sidebar variant={1} isOpen={isMenuOpen} mode="dark" />}
    </HeaderWrapper>
  );
}

/* 
	HeaderV3 is currently used in 
		- Current Offerings 
		- Investment Summary
		- Documents
		- Tax Center
		- Settings
*/
function HeaderV3({
  isMobile,
  isMenuOpen,
  handleToggleMenu,
  handleCloseMenu,
}: HeaderV3Props) {
  return (
    <HeaderWrapper
      backgroundColor={theme.colors.grey[0]}
      data-testid="header-wrapper"
    >
      <HeaderV3Wrapper>
        <Burger
          isOpen={isMenuOpen}
          handleClick={handleToggleMenu}
          color={theme.colors.grey[500]}
          width={isMobile ? 24 : 32}
          height={isMobile ? 24 : 32}
        />
        <LogoNavbar
          width={isMobile ? 173 : 111}
          height={isMobile ? 22 : 37}
          variant={'primary' as VariantTypes}
          strokeWidth={1}
        />
        <ProfileWrapper>
          <Profile isMobile={isMobile} />
        </ProfileWrapper>
      </HeaderV3Wrapper>

      <Sidebar
        variant={isMobile ? 1 : 2}
        isOpen={isMenuOpen}
        mode="light"
        handleClose={handleCloseMenu}
      />
    </HeaderWrapper>
  );
}
