import { UserJobResultDetails } from 'interfaces/graphql.types';

export const getInitialsFromUserJob = (userJob?: UserJobResultDetails) => {
  if (!userJob) return '';

  const firstName = userJob.contact?.firstName || '';
  const lastName = userJob.contact?.lastName || '';

  return `${firstName.slice(0, 1)}${lastName.slice(0, 1)}`;
};
