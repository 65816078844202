import styled, { css, keyframes } from 'styled-components';

const slideDown = keyframes`
  from {
    height: 0;
  }
  to {
    height: calc(100% - 4rem);
  }
`;

const slideUp = keyframes`
  from {
    height: calc(100% - 4rem);
  }
  to {
    height: 0;
  }
`;

interface SidebarV1WrapperProps {
  isOpen?: boolean;
  mode?: 'light' | 'dark';
}

export const SidebarV1Wrapper = styled.div<SidebarV1WrapperProps>`
  position: fixed;
  top: ${({ theme }) => theme.spacing[16]};
  width: 100%;
  background-color: ${({ theme }) => theme.colors.grey[0]};
  overflow: hidden;
  ${({ isOpen }) =>
    isOpen !== undefined
      ? css`
          animation: ${isOpen ? slideDown : slideUp} 0.5s ease-out forwards;
        `
      : css`
          height: 0;
        `}

  ${({ mode, theme }) =>
    mode === 'dark' &&
    `
		background-color: ${theme.colors.grey[600]};
	`}
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SidebarV1ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  display: grid;
  grid-template-rows: 1fr 80px;
`;

interface SideBarV2WrapperProps {
  active?: boolean;
  side: 'left' | 'right';
  isAdmin?: boolean;
  isSalesRep?: boolean;
}

export const SidebarV2Wrapper = styled.div<SideBarV2WrapperProps>`
  position: fixed;
  top: 0;
  z-index: 6;
  width: 350px;
  max-width: 80%;
  height: 100%;
  padding-bottom: 1.375rem;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
  background-color: ${({ theme }) => theme.colors.grey[0]};
  transition: 500ms;
  visibility: hidden;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  ${({ active, side }) => {
    if (side === 'left') {
      return active ? `visibility: visible;left: 0px;` : `left: -300px;`;
    } else {
      return active ? `visibility: visible;right: 0px;` : `right: -300px;`;
    }
  }}
`;

export const SidebarV2ContentWrapper = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: 1fr 80px;
`;

export const SidebarV2TopWrapper = styled.div``;

export const SidebarV2BottomWrapper = styled.div``;

interface SidebarDimProps {
  active?: boolean;
}

export const SidebarDim = styled.div<SidebarDimProps>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  background-color: transparent;
  width: 100vw;
  height: 100vh;

  transition: background-color 500ms;
  visibility: hidden;

  ${({ active }) =>
    active && `visibility: visible;background-color:rgba(0, 0, 0, 0.3)`}
`;
