import { LinkControl } from 'modules/shared/styles';
import Link from 'next/link';

import { Badge } from './styles';

const RecaptchaDisclaimer = () => (
  <Badge>
    This site is protected by reCAPTCHA and the Google{' '}
    <Link
      href="https://policies.google.com/privacy"
      target="_blank"
      rel="noreferrer"
    >
      {' '}
      <LinkControl>Privacy Policy</LinkControl>
    </Link>{' '}
    and{' '}
    <Link
      href="https://policies.google.com/terms"
      target="_blank"
      rel="noreferrer"
    >
      <LinkControl>Terms of Service</LinkControl>
    </Link>{' '}
    apply.
  </Badge>
);

export default RecaptchaDisclaimer;
