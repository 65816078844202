import styled from 'styled-components';

export const ClientsWrapper = styled.div`
  margin: 0 ${({ theme }) => theme.spacing[4]};
  [class*='SelectContainer'] {
    margin-bottom: 0;
    z-index: 99;
  }
`;

export const LoaderWrapper = styled.div`
  min-height: ${({ theme }) => theme.spacing[12]};
`;
