import React from 'react';

import { Path, Svg } from './styles';

interface BurgerIconProps {
  height?: number;
  width?: number;
  color?: string;
  isOpen?: boolean;
}

export const BurgerIcon: React.FC<BurgerIconProps> = ({
  height = 24,
  width = 24,
  color = '#AAAAAA',
  isOpen,
}: BurgerIconProps) => (
  <Svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <Path
      className={isOpen ? 'top-bun open' : 'top-bun'}
      d="M3 6H21"
      stroke={color}
      strokeWidth="2"
    />
    <Path
      className={isOpen ? 'meat open' : 'meat'}
      d="M3 12H21"
      stroke={color}
      strokeWidth="2"
    />
    <Path
      className={isOpen ? 'bottom-bun open' : 'bottom-bun'}
      d="M3 18H21"
      stroke={color}
      strokeWidth="2"
    />
  </Svg>
);
