import { useClient } from 'modules/settings/providers/ClientProvider';
import React from 'react';
import GlobalStyle from 'ui/theme/global-style';
import TimerLoader from 'ui/TimerLoader';
import { isMaxWidth } from 'utils/hooks/useWindow';

import Footer from './Footer';
import Header from './Header';
import { ChildrenWrapper, Wrapper } from './styles';
import { CustomStylesType, DataType, FooterParamsType } from './types';

interface LayoutProps {
  children: React.ReactNode;
  headerVariant?: number;
  footerVariant?: number;
  footerParams?: FooterParamsType;
  data?: DataType;
  customStyles?: CustomStylesType;
}

export default function Layout({
  children,
  headerVariant,
  footerVariant,
  footerParams = {},
  data = {},
  customStyles = {},
}: LayoutProps) {
  const { restartTimeLeft } = useClient();
  const isMobile = Boolean(isMaxWidth('md'));

  if (restartTimeLeft) return <TimerLoader timeLeft={restartTimeLeft} />;

  return (
    <>
      <GlobalStyle />
      <Wrapper
        backgroundColor={customStyles.backgroundColor}
        isBackgroundImage={customStyles.isBackgroundImage}
      >
        {headerVariant && (
          <Header isMobile={isMobile} variant={headerVariant} data={data} />
        )}
        <ChildrenWrapper minChildrenHeight={customStyles.minChildrenHeight}>
          {children}
        </ChildrenWrapper>
        {footerVariant && (
          <Footer
            isMobile={isMobile}
            variant={footerVariant}
            data={data}
            footerParams={footerParams}
          />
        )}
      </Wrapper>
    </>
  );
}
