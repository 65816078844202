import {
  SettingsGetPersonalInfoDetails,
  useSettingGetPersonalInfoMutation,
} from 'interfaces/graphql.types';
import { useEffect, useState } from 'react';
import { setSentryErrors } from 'utils/helpers/errorHandler';

export const useUserInfo = () => {
  const [userInfo, setUserInfo] = useState<SettingsGetPersonalInfoDetails>({});

  const [getPersonalInfo, { loading }] = useSettingGetPersonalInfoMutation();

  const getUserInfo = async () => {
    try {
      const result = await getPersonalInfo();
      if (result.data?.settingsGetPersonalInfo.success) {
        setUserInfo(result.data?.settingsGetPersonalInfo.userInfo || {});
      }
    } catch (e) {
      setSentryErrors(e);
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  return {
    userInfo,
    loading,
  };
};
