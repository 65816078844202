import React from 'react';
import { BurgerIcon } from 'ui/Icons/BurgerIcon';

import { Wrapper } from './styles';

interface BurgerProps {
  isOpen?: boolean;
  color?: string;
  height?: number;
  width?: number;
  handleClick?: () => void;
}

export function Burger({
  isOpen,
  color,
  width = 24,
  height = 24,
  handleClick,
}: BurgerProps) {
  return (
    <Wrapper onClick={handleClick} width={width} height={height}>
      <BurgerIcon isOpen={isOpen} color={color} width={width} height={height} />
    </Wrapper>
  );
}
