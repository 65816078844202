import { SettingsGetPersonalInfoDetails } from 'interfaces/graphql.types';

interface GetInputValueFromUserInfoParamsType {
  userInfo: SettingsGetPersonalInfoDetails;
  fields: string[];
}

export const getInputValueFromUserInfo = ({
  userInfo,
  fields,
}: GetInputValueFromUserInfoParamsType) =>
  fields.map((field) => userInfo[field]).join(' ');
