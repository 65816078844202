import { useAuth } from 'modules/auth/providers/AuthProvider';
import UserAvatar from 'stories/UserAvatar';
import { Identifications } from 'ui/theme/Header/Navbar/styles';

import { ClientSwitch } from '../ClientSwitch';
import { Copyright } from '../Copyright';
import { NavbarLinks } from '../NavbarLinks';
import {
  SidebarDim,
  SidebarV1ContentWrapper,
  SidebarV1Wrapper,
  SidebarV2BottomWrapper,
  SidebarV2ContentWrapper,
  SidebarV2TopWrapper,
  SidebarV2Wrapper,
} from './styles';

interface SidebarProps {
  variant: number;
  isOpen?: boolean;
  mode?: 'light' | 'dark';
  handleClose?: () => void;
}

export function Sidebar({ variant, isOpen, mode, handleClose }: SidebarProps) {
  const { state, logout } = useAuth();

  const user = {
    firstName: state.userJob?.contact?.firstName || '',
    lastName: state.userJob?.contact?.lastName || '',
  };

  const handleLogout = () => {
    logout();
    handleClose && handleClose();
  };

  switch (variant) {
    case 1: {
      return (
        <SidebarV1Wrapper isOpen={isOpen} mode={mode}>
          <SidebarV1ContentWrapper>
            <NavbarLinks />
            <Copyright pos="center" mode={mode} />
          </SidebarV1ContentWrapper>
        </SidebarV1Wrapper>
      );
    }
    case 2: {
      return (
        <>
          <SidebarDim active={isOpen} onClick={handleClose} />
          <SidebarV2Wrapper active={Boolean(isOpen)} side="left">
            <SidebarV2ContentWrapper>
              <SidebarV2TopWrapper>
                {state.loggedIn && (
                  <Identifications>
                    <UserAvatar
                      size="large"
                      type="monogram"
                      user={user}
                      withFullNameLabel
                    />
                  </Identifications>
                )}
                <ClientSwitch callback={handleLogout} />
                <NavbarLinks callback={handleClose} />
              </SidebarV2TopWrapper>

              <SidebarV2BottomWrapper>
                <Copyright mode={mode} />
              </SidebarV2BottomWrapper>
            </SidebarV2ContentWrapper>
          </SidebarV2Wrapper>
        </>
      );
    }
    default: {
      return null;
    }
  }
}
