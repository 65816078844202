import styled from 'styled-components';
import { colorWithOpacity } from 'utils/helpers/colorWithOpacity';

interface NavbarLinkWrapperProps {
  isActive?: boolean;
  activeBarColor?: string;
}

export const NavbarLinkWrapper = styled.div<NavbarLinkWrapperProps>`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing[4]};
  gap: ${({ theme }) => theme.spacing[2]};
  span {
    font-family: ${({ theme }) => theme.fonts.secondary};
    color: ${({ theme }) => theme.colors.grey[700]};
    font-weight: ${({ theme }) => theme.fonts.weight.medium};
  }

  ${({ isActive, theme }) =>
    !isActive &&
    `
	  &:hover {
			background-color: ${colorWithOpacity(theme.colors.grey[700], 0.1)};
		}
		&:active {
			background-color: ${colorWithOpacity(theme.colors.grey[700], 0.2)};
		}
	`}

  position: relative;

  ${({ isActive }) =>
    isActive
      ? `
	cursor: default;
	`
      : `
	cursor: pointer;
	`}

  ${({ activeBarColor, theme }) =>
    activeBarColor &&
    `
	&::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 4px;
    pointer-events: none;
    background: ${activeBarColor};
		@media (max-width: ${theme.breakpoints.md}) {
			background: none;
		}
  }
	`}
`;
