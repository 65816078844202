import { APP_VERSION } from 'config';
import Image from 'next/legacy/image';

import {
  AppVersion,
  AppVersionAndCopyrightWrapper,
  AppVersionWrapper,
  CopyrightWrapper,
} from './styles';

interface CopyrightProps {
  pos?: 'center' | 'left';
  mode?: 'light' | 'dark';
}

export function Copyright({ pos, mode }: CopyrightProps) {
  return (
    <AppVersionAndCopyrightWrapper pos={pos} mode={mode}>
      <AppVersionWrapper>
        <Image
          src="/assets/relevant-app-icon.png"
          layout="fixed"
          width={32}
          height={32}
        />
        {APP_VERSION.version && (
          <AppVersion mode={mode}>v. {APP_VERSION.version}</AppVersion>
        )}
      </AppVersionWrapper>
      <CopyrightWrapper mode={mode}>
        <p>
          © {`${new Date().getFullYear()} `}
          <a href="https://relevant.us" target="_blank">
            Relevant Equity Systems Inc.
          </a>
        </p>
        <p>All rights reserved</p>
      </CopyrightWrapper>
    </AppVersionAndCopyrightWrapper>
  );
}
