import styled from 'styled-components';

export const FooterNav = styled.footer`
  width: 100%;
  height: 3.125rem;
  position: fixed;
  bottom: 0;
  border-top: 0.063rem solid var(--grey-color);
  z-index: 3;
  background-color: ${({ theme }) => theme.colors.grey[800]};
  display: flex;
  justify-content: center;
  align-content: center;
  padding-top: 0.5rem;
  padding-bottom: 3.5rem;
`;

export const FooterNavButtons = styled.div<{ client?: string }>`
  display: grid;
  grid-template-columns: ${({ client }) =>
    client === 'solyco' ? '1fr 1fr' : '1fr 1fr 1fr'};
  gap: 1.87rem;
  max-width: 13rem;
  height: 100%;
`;
