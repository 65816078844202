import { MVP_ENABLED } from 'config';
import Link from 'next/link';
import { Item } from 'react-flex-ready';
import RecaptchaDisclaimer from 'stories/RecaptchaDislaimer';
import theme from 'stories/theme';
import Divider from 'ui/Divider';
import LogoFooter from 'ui/Logos/LogoFooter';
import { VariantTypes } from 'ui/uiTypes';

import footerData from './footerData';
import {
  Address,
  Email,
  Links,
  Phone,
  RecaptchWrapper,
  SubLinks,
  SubWrapper,
  Wrapper,
} from './styles';

interface FooterProps {
  client: string;
  backgroundColor?: string;
}

const Footer = ({ client, backgroundColor }: FooterProps) => (
  <Wrapper backgroundColor={backgroundColor}>
    <Item col={6} colTablet={12} colMobile={12}>
      <SubWrapper>
        <Link href={MVP_ENABLED ? '/dashboard' : '/'}>
          <LogoFooter
            height={72}
            width={234}
            variant={VariantTypes.SECONDARY}
            client={client}
          />
        </Link>
        <Address>
          {footerData(client).address.map((elem, i) => (
            <p key={i}>{elem}</p>
          ))}
        </Address>
        <Phone>
          <a href={`tel:${footerData(client).phone}`}>
            {footerData(client).phone}
          </a>
        </Phone>
        <Email>
          <a href={`mailto:${footerData(client).email}`}>
            {footerData(client).email}
          </a>
        </Email>
        <RecaptchWrapper>
          <RecaptchaDisclaimer />
        </RecaptchWrapper>
      </SubWrapper>
    </Item>
    <Divider mobileHidden={false} color={theme.colors.grey[500]} />
    <Item col={6} colTablet={12} colMobile={12}>
      <Links>
        {footerData(client).links &&
          Array.from({
            length: Math.ceil(footerData(client).links!.length / 4),
          }).map((_, rowIndex) => (
            <SubLinks key={rowIndex}>
              {footerData(client)
                .links!.slice(rowIndex * 4, (rowIndex + 1) * 4)
                .map((link) => (
                  <a key={link.title} href={link.href}>
                    {link.title}
                  </a>
                ))}
            </SubLinks>
          ))}
      </Links>
    </Item>
  </Wrapper>
);

export default Footer;
