import styled from 'styled-components';

interface SvgProps {
  height: number;
  width: number;
  rotate?: string;
}

export const Svg = styled.svg<SvgProps>`
  height: ${({ height }) => height}px;
  width: ${({ width }) => width}px;
  cursor: pointer;

  .top-bun {
    transform-origin: center;
    transform: rotate(0);
  }

  .bottom-bun {
    transform-origin: center;
    transform: rotate(0);
  }

  .meat {
    opacity: 1;
  }

  .open.top-bun {
    transform: rotate(45deg) translate(0px, 6px);
  }

  .open.bottom-bun {
    transform: rotate(-45deg) translate(0px, -6px);
  }

  .open.meat {
    opacity: 0;
  }
`;

export const Path = styled.path`
  transition: all 0.3s ease;
`;
