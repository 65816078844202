import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import {
  DocIcon,
  InvestmentIcon,
  MySettingsIcon,
  SignIn as SignInIcon,
  SignOutIcon,
  TagIcon,
  TaxCenterIcon,
} from 'ui/Icons';

import { NavbarLinkWrapper } from './styles';

interface GetNavbarLinkIconProps {
  href: string;
  isActive: boolean;
}

type GetActiveBarProps = GetNavbarLinkIconProps;

interface NavbarLinkProps {
  label: string;
  href: string;
  withoutLink?: boolean;
  handleClick?: () => void;
}

const getNavbarLinkIcon = ({ href, isActive }: GetNavbarLinkIconProps) => {
  switch (href) {
    case '/current-offerings': {
      return <TagIcon isActive={isActive} />;
    }
    case '/dashboard': {
      return <InvestmentIcon isActive={isActive} />;
    }
    case '/documents': {
      return <DocIcon isActive={isActive} />;
    }
    case '/tax-center': {
      return <TaxCenterIcon isActive={isActive} />;
    }
    case '/settings': {
      return <MySettingsIcon isActive={isActive} />;
    }
    case '/signout': {
      return <SignOutIcon isActive={isActive} />;
    }
    case '/auth/signin': {
      return <SignInIcon />;
    }
  }
};

const getActiveBarColor = ({ href, isActive }: GetActiveBarProps) => {
  if (!isActive) return;
  switch (href) {
    case '/current-offerings': {
      return 'linear-gradient(45deg, #BDF420, #12A08F)';
    }
    case '/dashboard': {
      return 'linear-gradient(to bottom right, #20F8F8, #017AE9)';
    }
    case '/documents': {
      return 'linear-gradient(to top left, #370DB0, #9854EF)';
    }
    case '/tax-center': {
      return 'linear-gradient(to bottom right, #FF6767, #AD0015)';
    }
    case '/settings': {
      return 'linear-gradient(to bottom right, #AAAAAA, #222222)';
    }
    case '/signout': {
      return 'linear-gradient(to top left, #AAAAAA, #222222)';
    }
    case '/auth/signin': {
      return 'linear-gradient(to top left, #AAAAAA, #222222)';
    }
  }
};

export default function NavbarLink({
  label,
  href,
  withoutLink,
  handleClick,
}: NavbarLinkProps) {
  const router = useRouter();
  const isCurrentPath = router.pathname === href;
  const [isActive, setIsActive] = useState(isCurrentPath);

  const handleMouseEnter = () => {
    if (isCurrentPath) return;
    setIsActive(true);
  };

  const handleMouseLeave = () => {
    if (isCurrentPath) return;
    setIsActive(false);
  };

  const isWithoutLink = Boolean(withoutLink || isCurrentPath);

  const item = (
    <NavbarLinkWrapper
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={isCurrentPath ? undefined : handleClick}
      isActive={isCurrentPath}
      activeBarColor={getActiveBarColor({ href, isActive: isCurrentPath })}
    >
      {getNavbarLinkIcon({ href, isActive })}
      <span data-cy="summary">{label}</span>
    </NavbarLinkWrapper>
  );

  return isWithoutLink ? item : <Link href={href}>{item}</Link>;
}
