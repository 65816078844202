import { PUBLIC_IMAGE_BUCKET } from 'config';
import styled from 'styled-components';

interface WrapperProps {
  backgroundColor?: string;
  isBackgroundImage?: boolean;
}
export const Wrapper = styled.div<WrapperProps>`
  ${({ backgroundColor }) =>
    backgroundColor &&
    `
		background-color: ${backgroundColor};
	`};

  ${({ isBackgroundImage }) =>
    isBackgroundImage &&
    `
		background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url(https://${PUBLIC_IMAGE_BUCKET}.s3.amazonaws.com/default_bg.jpg);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
	`};
`;

interface ChildrenWrapperProps {
  minChildrenHeight?: string;
}

export const ChildrenWrapper = styled.div<ChildrenWrapperProps>`
  min-height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  ${({ minChildrenHeight }) =>
    minChildrenHeight &&
    `
		min-height: ${minChildrenHeight};
	`}
`;
