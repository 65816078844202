import { useAuth } from 'modules/auth/providers/AuthProvider';
import { useClient } from 'modules/settings/providers/ClientProvider';
import Link from 'next/link';

import NavbarLink from '../NavbarLink';
import {
  AppNavbarLinksWrapper,
  HomeNavbarLinkOption,
  HomeNavbarLinksWrapper,
} from './styles';

interface LinkType {
  link: string;
  label: string;
  hideFrom: string[];
  isAuth?: boolean;
  isLogoutLink?: boolean;
}

const homePageLinks: LinkType[] = [
  {
    label: 'Current Offerings',
    link: '/current-offerings',
    hideFrom: ['core', 'metro', 'jvm', 'solyco', 'rmr', 'wellspring'],
  },
  {
    label: 'Investors',
    link: '#',
    hideFrom: ['core', 'metro', 'jvm', 'solyco', 'rmr', 'wellspring'],
  },
  {
    label: 'Meet Our Team',
    link: '#',
    hideFrom: ['core', 'metro', 'jvm', 'solyco', 'rmr', 'wellspring'],
  },
  {
    label: 'Contact',
    link: '#',
    hideFrom: ['core', 'metro', 'jvm', 'solyco', 'rmr', 'wellspring'],
  },
  {
    label: 'Investor Portal',
    link: '/dashboard',
    hideFrom: [],
  },
  {
    label: 'Admin Portal',
    link: '/admin/offerings',
    hideFrom: ['core', 'metro', 'jvm', 'solyco', 'rmr', 'wellspring'],
  },
  {
    label: 'Sales Rep Portal',
    link: '/salesrep/investors',
    hideFrom: ['core', 'metro', 'jvm', 'solyco', 'rmr', 'wellspring'],
  },
];

const appLinks: LinkType[] = [
  {
    label: 'Offerings',
    link: '/current-offerings',
    hideFrom: ['core', 'metro', 'jvm', 'solyco', 'rmr', 'wellspring'],
  },
  {
    label: 'Investment Summary',
    link: '/dashboard',
    hideFrom: [],
    isAuth: true,
  },
  {
    label: 'Documents',
    link: '/documents',
    hideFrom: [],
    isAuth: true,
  },
  {
    label: 'Tax Center',
    link: '/tax-center',
    hideFrom: ['rmr', 'wellspring'],
    isAuth: true,
  },
  {
    label: 'Settings',
    link: '/settings',
    hideFrom: ['wellspring'],
    isAuth: true,
  },
  {
    label: 'Sign Out',
    link: '/signout',
    hideFrom: [],
    isAuth: true,
    isLogoutLink: true,
  },
  {
    label: 'Sign In',
    link: '/auth/signin',
    hideFrom: [],
    isAuth: false,
  },
];

interface NavbarLinksProps {
  type?: string;
  callback?: () => void;
}

export function NavbarLinks({ callback, type = 'app' }: NavbarLinksProps) {
  const { client } = useClient();
  const { state, logout } = useAuth();

  const filterLinks = (links: LinkType[]) =>
    links.filter(
      (link) =>
        !link.hideFrom.includes(client) &&
        (state.loggedIn === link.isAuth || link.isAuth === undefined)
    );

  const handleLogout = () => {
    logout();
    callback && callback();
  };

  switch (type) {
    case 'home': {
      const links = filterLinks(homePageLinks);
      return (
        <HomeNavbarLinksWrapper>
          {links.map((val, i) => (
            <Link href={val.link} data-cy={val.link} key={i}>
              <HomeNavbarLinkOption>{val.label}</HomeNavbarLinkOption>
            </Link>
          ))}
        </HomeNavbarLinksWrapper>
      );
    }
    case 'app': {
      const links = filterLinks(appLinks);
      return (
        <AppNavbarLinksWrapper>
          {links.map((link, index) => (
            <NavbarLink
              key={index}
              label={link.label}
              href={link.link}
              handleClick={link.isLogoutLink ? handleLogout : callback}
            />
          ))}
        </AppNavbarLinksWrapper>
      );
    }
    default: {
      return null;
    }
  }
}
