import { useClient } from 'modules/settings/providers/ClientProvider';
import { useRouter } from 'next/router';
import React from 'react';
import AdminButtonNavigation from 'ui/AdminButtonNavigation';
import * as Icons from 'ui/Icons';
import NavSideBar from 'ui/NavSideBar';
import SalesRepButtonNavigation from 'ui/SalesRepButtonNavigation';
import {
  NavigationContainer,
  NavItem,
  SideBarContainer,
} from 'ui/theme/Header/styles';

import { FooterNav, FooterNavButtons } from './styles';

const getSalesRepNav = (client: string) => {
  switch (client) {
    case 'solyco': {
      return [
        {
          hrefLink: '/salesrep/investors',
          Icon: 'Users',
          LinkTitle: 'Relationships',
        },
        {
          hrefLink: '/salesrep/profile',
          Icon: 'Profile',
          LinkTitle: 'Profile',
        },
        {
          hrefLink: '/',
          Icon: 'Logout',
          LinkTitle: 'Log out',
        },
      ];
    }
    default:
      return [
        {
          hrefLink: '/salesrep/offerings',
          Icon: 'Properties',
          LinkTitle: 'Offerings',
        },
        {
          hrefLink: '/salesrep/investors',
          Icon: 'Users',
          LinkTitle: 'Investors',
        },
        {
          hrefLink: '/salesrep/profile',
          Icon: 'Profile',
          LinkTitle: 'Profile',
        },
        {
          hrefLink: '/',
          Icon: 'Logout',
          LinkTitle: 'Log out',
        },
      ];
  }
};

export function SalesRepMobileNavigation() {
  const { client } = useClient();
  const router = useRouter();
  const salesRepNav = getSalesRepNav(client);

  return (
    <FooterNav>
      <FooterNavButtons data-testid="navButtons" client={client}>
        {salesRepNav.map((link, i) => {
          const isActive =
            router.pathname.includes(link.hrefLink) ||
            (link.hrefLink == '/salesrep/offerings' &&
              router.pathname.includes('/salesrep/offering'));
          return (
            <SalesRepButtonNavigation
              key={i}
              hrefLink={link.hrefLink}
              Icon={Icons[link.Icon]}
              LinkTitle={link.LinkTitle}
              active={isActive}
            />
          );
        })}
      </FooterNavButtons>
    </FooterNav>
  );
}

export function SalesrepDesktopNavigation() {
  const { client } = useClient();

  const salesRepNav = getSalesRepNav(client);

  return (
    <NavSideBar active={true} side="left" isSalesRep={true}>
      <SideBarContainer>
        <NavigationContainer>
          {salesRepNav.map((val) => (
            <NavItem key={`${val.hrefLink}-${val.LinkTitle}`}>
              <AdminButtonNavigation
                hrefLink={val.hrefLink}
                Icon={Icons[val.Icon]}
                LinkTitle={val.LinkTitle}
              />
            </NavItem>
          ))}
        </NavigationContainer>
      </SideBarContainer>
    </NavSideBar>
  );
}
