import SolycoFooter from 'modules/salesrep/shared/components/SolycoFooter';
import { useClient } from 'modules/settings/providers/ClientProvider';
import FooterV2 from 'stories/Footer';
import {
  SalesrepDesktopNavigation,
  SalesRepMobileNavigation,
} from 'ui/SalesrepNavigation';

import { DataType, FooterParamsType } from '../types';
interface FooterProps {
  isMobile?: boolean;
  variant: number;
  data: DataType;
  footerParams?: FooterParamsType;
}

interface FooterV1Props extends FooterParamsType {
  isMobile?: boolean;
}

export default function Footer({
  isMobile,
  variant,
  footerParams,
}: FooterProps) {
  const { client } = useClient();
  switch (variant) {
    case 1: {
      return <FooterV1 isMobile={isMobile} {...footerParams} />;
    }
    case 2: {
      return (
        <FooterV2
          client={client}
          backgroundColor={footerParams?.backgroundColor}
        />
      );
    }
    default: {
      return null;
    }
  }
}

function FooterV1({ isMobile, hideLeft, hideBottom }: FooterV1Props) {
  const { client } = useClient();
  return (
    <>
      {isMobile ? (
        <SalesRepMobileNavigation />
      ) : (
        <>
          {!hideLeft && <SalesrepDesktopNavigation />}
          {!hideBottom && client === 'solyco' && <SolycoFooter />}
        </>
      )}
    </>
  );
}
