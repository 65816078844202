import styled from 'styled-components';

export const ProfileWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing[2]};
`;

interface AvatarWrapperProps {
  size?: 'small' | 'medium' | 'large';
}

export const AvatarWrapper = styled.div<AvatarWrapperProps>`
  width: 32px;
  height: 32px;
  border-radius: 100px;
  background-color: ${({ theme }) => theme.colors.primary[200]};
  color: ${({ theme }) => theme.colors.primary.main};
  font-weight: ${({ theme }) => theme.fonts.weight.semiBold};
  display: flex;
  align-items: center;
  justify-content: center;
  tex-transform: uppercase;
  cursor: pointer;
  ${({ size, theme }) =>
    size === 'large' &&
    `
		width: 156px;
		height: 156px;
		font-size: ${theme.fonts.size.xl10};
	`};

  ${({ size, theme }) =>
    size === 'medium' &&
    `
	width: 40px;
	height: 40px;
	font-size: ${theme.fonts.size.lg};
`};
`;

interface ProfileModalWrapperProps {
  isOpen?: boolean;
}

export const ProfileModalWrapper = styled.div<ProfileModalWrapperProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.grey[0]};
  z-index: 300;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s ease, visibility 0.3s ease;

  ${({ isOpen }) =>
    !isOpen &&
    `
    opacity: 0;
    visibility: hidden;
    `}
`;

export const ProfileModalHeaderWrapper = styled.div`
  margin: ${({ theme }) => theme.spacing[8]} 0;
  display: flex;
  justify-content: center;
  position: relative;
`;

export const ProfileModalContentWrapper = styled.div`
  position: relative;
  height: 100%;
  overflow: scroll;
  padding: ${({ theme }) => theme.spacing[8]} ${({ theme }) => theme.spacing[4]};
`;

export const ProfileUsernameTitle = styled.h3`
  font-size: ${({ theme }) => theme.fonts.size.base};
  font-weight: ${({ theme }) => theme.fonts.weight.medium};
  color: ${({ theme }) => theme.colors.grey[800]};
`;

export const CloseWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

export const CustomEditButton = styled.div`
  background-color: ${({ theme }) => theme.colors.grey[0]};
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing[1]};
  color: ${({ theme }) => theme.colors.primary.main};
  font-weight: ${({ theme }) => theme.fonts.weight.medium};
  margin-top: -${({ theme }) => theme.spacing[4]};
  border-radius: 100px;
  width: 93px;
  height: 32px;
  cursor: pointer;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
  transition: transform 0.1s ease, box-shadow 0.2s ease;

  &:hover {
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.15);
    transform: translate(-1px, 1px);
  }
  &:active {
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.15);
    background-color: ${({ theme }) => theme.colors.grey[200]};
  }
`;

export const AvatarAndEditWrapper = styled.div`
  position: relative;
`;

export const ModalTitle = styled.h3`
  font-size: ${({ theme }) => theme.fonts.size.xl3};
  font-weight: ${({ theme }) => theme.fonts.weight.medium};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
`;

export const InputsCardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${({ theme }) => theme.spacing[4]} 0;
  gap: ${({ theme }) => theme.spacing[2]};
`;

export const InputCardWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing[5]};
  background-color: ${({ theme }) => theme.colors.grey[100]};
  border: 1px solid ${({ theme }) => theme.colors.grey[300]};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  display: grid;
  grid-template-columns: 1fr 20px;
  align-items: center;
`;

export const InputCardContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[2]};
`;

export const InputCardLabel = styled.div`
  font-size: ${({ theme }) => theme.fonts.size.xs1};
  color: ${({ theme }) => theme.colors.grey[400]};
`;

export const InputCardValue = styled.div``;

export const SignOutButtonWrapper = styled.div`
  [class*='NavbarLinkWrapper'] {
    display: flex;
    justify-content: center;
    border-radius: 5px;
  }
`;

export const InputCardRedirectWrapper = styled.div``;
