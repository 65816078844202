import styled from 'styled-components';

export const HomeNavbarLinksWrapper = styled.div`
  display: flex;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: ${({ theme }) => theme.spacing[6]};
  }
`;

export const HomeNavbarLinkOption = styled.div`
  color: ${({ theme }) => theme.colors.grey[0]};
  padding: ${({ theme }) => theme.spacing[4]};
  font-weight: ${({ theme }) => theme.fonts.weight.medium};
  border-radius: ${({ theme }) => theme.spacing[2]};
`;

export const AppNavbarLinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: ${({ theme }) => theme.spacing[6]};
  }
`;
