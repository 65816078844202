import styled from 'styled-components';

interface CommonProps {
  mode?: 'light' | 'dark';
}

interface AppVersionAndCopyrightWrapperProps extends CommonProps {
  pos?: 'center' | 'left';
}

export const AppVersionAndCopyrightWrapper = styled.div<AppVersionAndCopyrightWrapperProps>`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: ${({ theme }) => theme.spacing[4]};
  gap: ${({ theme }) => theme.spacing[2]};
  width: 100%;
  ${({ pos }) =>
    pos === 'center' &&
    `
		align-items: center;
		justify-content: center;
		text-align: center;
	`}
`;

export const AppVersionWrapper = styled.div<CommonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing[3]};
`;

export const AppVersion = styled.div<CommonProps>`
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.grey[700]};
  ${({ mode, theme }) =>
    mode === 'dark' &&
    `
		color: ${theme.colors.grey[0]};
	`}
`;

export const CopyrightWrapper = styled.div<CommonProps>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[1]};
  a {
    color: inherit;
    text-decoration: underline;
  }
  p {
    font-size: 12px;
    padding: 0;
    margin: 0;
  }

  ${({ mode, theme }) =>
    mode === 'dark' &&
    `
		color: ${theme.colors.grey[400]};
	`}
`;
