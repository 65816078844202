import styled from 'styled-components';
import { colorWithOpacity } from 'utils/helpers/colorWithOpacity';

interface WrapperProps {
  width: number;
  height: number;
}

export const Wrapper = styled.div<WrapperProps>`
  margin-left: ${({ theme }) => theme.spacing[4]};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  cursor: pointer;

  transition: all 0.1s ease;

  &:hover {
    background-color: ${({ theme }) =>
      colorWithOpacity(theme.colors.grey[500], 0.1)};
  }

  &:active {
    background-color: ${({ theme }) =>
      colorWithOpacity(theme.colors.grey[600], 0.2)};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: ${({ width }) => width + 8}px;
    height: ${({ height }) => height + 8}px;
    border-radius: 5px;

    svg {
      transition: all 0.1s ease;

      &:hover {
        height: ${({ height }) => height + 4}px;
        width: ${({ width }) => width + 4}px;
      }

      &:active {
        height: ${({ height }) => height}px;
        width: ${({ width }) => width}px;
        color: ${({ theme }) => theme.colors.grey[600]};
      }
    }
  }
`;
