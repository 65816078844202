import theme from 'stories/theme';
import styled from 'styled-components';

export const Badge = styled.div`
  display: block;
  line-height: 1.33;
  font-size: ${theme.spacing[2.5]};
  font-weight: ${theme.fonts.weight.normal};
  font-family: ${theme.fonts.family.secondary};
  color: ${theme.colors.grey[400]};
  a text {
    text-decoration: underline;
  }
`;
